const mutations = {
  LOGIN(state, data) {
    state.isLogin = true;
    localStorage.setItem("accesstoken", data);
  },
  LOGOUT(state) {
    state.isLogin = false;
    state.isAdmin = false;
    state.email = "";
    state.mobileNo = "";
    state.username = "";
    state.id = null;
    state._id = null;
    localStorage.removeItem("accesstoken");
  },
  SET_NAVBAR(state, data) {
    state.navbarState = data;
  },
  SET_NAVBAR_VISIBLE(state, data) {
    state.navVisible = data;
  },
  SET_FOOTER(state, data) {
    state.footerVisible = data.visible;
  },

  SET_USER_INFO(state, data) {
    if (data.email) {
      state.email = data.email;
    }
    if (data.id) {
      state.id = data.id;
    }
    if (data._id) {
      state._id = data._id;
    }

    if (data.avatar) {
      state.avatar = data.avatar;
    }
    if (data.tags) {
      state.tags = data.tags;
    }
    if (data.isAdmin) {
      state.isAdmin = data.isAdmin;
    }
    if (data.mobileNo) {
      state.mobileNo = data.mobileNo;
    }
    if (data.status) {
      state.status = data.status;
    }

    if (data.username) {
      state.username = data.username;
    }
    if (data.nickname) {
      state.nickname = data.nickname;
    }
    if (data.userType) {
      state.userType = data.userType;
    }
  },
  SET_TOY_REQUESTCOUNT(state, data) {
    state.toyRequestCount = data;
  },
  SET_PROGRAM_REQUESTCOUNT(state, data) {
    state.tvRequestCount = data;
  },
};
export default mutations;
