import { fetchProfile } from "@/api/index";
import { fetchToyRequestList, fetchProgramRequestList } from "@/api/admin";

const actions = {
  /**
   * LOGIN
   */
  LOGIN({ commit }, data) {
    commit("LOGIN", data);
    commit("SET_USER_INFO", data);
  },
  /*
   * LOGOUT
   */
  LOGOUT({ commit }) {
    commit("LOGOUT");
    window.location.href = "/";
  },

  /**
   * fetch User 정보
   */
  getAccountInfo({ commit, dispatch }) {
    fetchProfile().then((res) => {
      if (res.data.status == 200 && res.data.data) {
        console.log("getAccountInfo", res);
        let data = {
          email: res.data.data.email,
          _id: res.data.data._id,
          id: res.data.data.id,
          isAdmin: res.data.data.isAdmin,
          mobileNo: res.data.data.mobileNo,
          username: res.data.data.username,
          userType: res.data.data.userType,
          nickname: res.data.data.nickname,
          status: res.data.data.status, //회원 가입 처리 상태
        };
        commit("SET_USER_INFO", data);
      } else {
        dispatch("LOGOUT");
      }
    });
  },
  getNewRequestList({ commit }) {
    let params = {
      status: "REQUEST",
    };
    fetchToyRequestList(params).then((res) => {
      let requestCount = res.data.data.length;
      commit("SET_TOY_REQUESTCOUNT", requestCount);
    });
    fetchProgramRequestList(params).then((res) => {
      let requestCount = res.data.data.length;
      commit("SET_PROGRAM_REQUESTCOUNT", requestCount);
    });
  },
  SET_NAVBAR({ commit }, data) {
    commit("SET_NAVBAR", data);
  },
  SET_NAVBAR_VISIBLE({ commit }, data) {
    commit("SET_NAVBAR_VISIBLE", data);
  },
  SET_FOOTER({ commit }, data) {
    commit("SET_FOOTER", data);
  },
  SET_USER_INFO({ commit }, data) {
    commit("SET_USER_INFO", data);
  },
};

export default actions;
