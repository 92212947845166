// export const baseUrl = "http://localhost:30001";
export const baseUrl = "https://api.gapnote.kr";
export const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "X-Content-Type-Options": "nosniff",
  authorization: localStorage.getItem("accesstoken"),
};

export const storageBaseUrl = "";
export const CALL_CENTER = "";
