const state = {
  id: null,
  _id: null,
  email: "", //이메일
  status: "",
  mobileNo: "",
  username: "",
  userType: "",
  navbarState: "0",
  isLogin: false,
  isAdmin: false,
};
export default state;
