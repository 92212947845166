import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

// NavigationDuplicated Error 해결 코드
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

const requireAdmin = () => (to, from, next) => {
  let accesstoken = localStorage.getItem("accesstoken");
  if (accesstoken) {
    next();
  } else {
    next("/");
  }
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/account/Login.vue"),
  },

  {
    path: "/account",
    name: "account",
    component: () => import("../views/account/Container.vue"),

    children: [
      {
        path: "login",
        name: "login",
        component: () => import("../views/account/Login.vue"),
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin/Container.vue"),
    beforeEnter: requireAdmin(),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("../views/admin/Dashboard.vue"),
      },

      {
        path: "users",
        name: "users",
        component: () => import("../views/admin/Users.vue"),
      },

      {
        path: "userDetail",
        name: "userDetail",
        component: () => import("../views/admin/UserDetail.vue"),
      },

      {
        path: "growths",
        name: "growths",
        component: () => import("../views/admin/Growths.vue"),
      },
      {
        path: "growthDetail",
        name: "growthDetail",
        component: () => import("../views/admin/GrowthDetail.vue"),
      },
      {
        path: "farms",
        name: "farms",
        component: () => import("../views/admin/Farms.vue"),
      },
      {
        path: "farmDetail",
        name: "farmDetail",
        component: () => import("../views/admin/FarmDetail.vue"),
      },
      {
        path: "cultureInfos",
        name: "cultureInfos",
        component: () => import("../views/admin/CultureInfos.vue"),
      },
      {
        path: "cultureInfoRegister",
        name: "cultureInfoRegister",
        component: () => import("../views/admin/CultureInfoRegister.vue"),
      },
      {
        path: "devices",
        name: "devices",
        component: () => import("../views/admin/Devices.vue"),
      },
      {
        path: "deviceRegister",
        name: "deviceRegister",
        component: () => import("../views/admin/DevicesRegister.vue"),
      },
      {
        path: "notices",
        name: "notices",
        component: () => import("../views/admin/Notices.vue"),
      },
      {
        path: "noticesRegister",
        name: "noticesRegister",
        component: () => import("../views/admin/NoticeRegister.vue"),
      },
      {
        path: "diarys",
        name: "diarys",
        component: () => import("../views/admin/Diarys.vue"),
      },
      {
        path: "diaryDetail",
        name: "diaryDetail",
        component: () => import("../views/admin/DiaryDetail.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { x: 0, y: 0 };
      // return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
