import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function fetchUserList(params) {
  return axios.get(baseUrl + `/admin/user/list`, { params, headers });
}
export function fetchUserDetails(id) {
  return axios.get(baseUrl + `/admin/user/${id}`, { headers });
}
export function upadateUserDetails(data) {
  return axios.put(baseUrl + `/admin/user`, data, { headers });
}
export function fetchDashCount() {
  return axios.get(baseUrl + `/admin/user/dashboard`, { headers });
}
export function createDevice(data) {
  return axios.post(baseUrl + `/admin/device`, data, { headers });
}
export function fetchDeviceList(params) {
  return axios.get(baseUrl + `/admin/device/list`, { params, headers });
}
export function fetchDeviceDetails(id) {
  return axios.get(baseUrl + `/admin/device/${id}`, { headers });
}
export function upadateDeviceDetails(data) {
  return axios.put(baseUrl + `/admin/device`, data, { headers });
}
export function removeDevice(id) {
  return axios.delete(baseUrl + `/admin/device/${id}`, { headers });
}
export function createNotice(data) {
  return axios.post(baseUrl + `/admin/notice`, data, { headers });
}
export function createNoticeFcm(data) {
  return axios.post(baseUrl + `/accounts/fcm/pushAll`, data, { headers });
}
export function fetchNoticeList(params) {
  return axios.get(baseUrl + `/admin/notice/list`, { params, headers });
}
export function fetchNoticeDetails(id) {
  return axios.get(baseUrl + `/admin/notice/${id}`, { headers });
}
export function upadateNoticeDetails(data) {
  return axios.put(baseUrl + `/admin/notice`, data, { headers });
}
export function removeNotice(id) {
  return axios.delete(baseUrl + `/admin/notice/${id}`, { headers });
}
export function createCultureInfo(data) {
  return axios.post(baseUrl + `/admin/cultureInfo`, data, { headers });
}
export function fetchCultureInfoList(params) {
  return axios.get(baseUrl + `/admin/cultureInfo/list`, { params, headers });
}
export function fetchCultureInfoDetails(id) {
  return axios.get(baseUrl + `/admin/cultureInfo/${id}`, { headers });
}
export function upadateCultureInfoDetails(data) {
  return axios.put(baseUrl + `/admin/cultureInfo`, data, { headers });
}
export function removeCultureInfo(id) {
  return axios.delete(baseUrl + `/admin/cultureInfo/${id}`, { headers });
}
export function updateFarm(data) {
  return axios.put(baseUrl + `/farm`, data, { headers });
}
export function fetchFarmsList(params) {
  return axios.get(baseUrl + `/admin/farm/list`, { params, headers });
}
export function fetchFarmDetails(id) {
  return axios.get(baseUrl + `/admin/farm/${id}`, { headers });
}
export function removeFarm(id) {
  return axios.delete(baseUrl + `/admin/farm/${id}`, { headers });
}
export function fetchGrowthsList(params) {
  return axios.get(baseUrl + `/admin/growth/list`, { params, headers });
}
export function fetchGrowthDumpList(params) {
  return axios.get(baseUrl + `/admin/growth/dump/list`, { params, headers });
}
export function fetchGrowthDetails(id) {
  return axios.get(baseUrl + `/admin/growth/${id}`, { headers });
}

export function fetchDiaryList(params) {
  return axios.get(baseUrl + `/admin/diary/list`, { params, headers });
}
export function fetchDiaryDetail(id) {
  return axios.get(baseUrl + `/admin/diary/${id}`, { headers });
}
export function fetchDiaryDumpList(params) {
  return axios.get(baseUrl + `/admin/diary/dump/list`, { params, headers });
}

// 기존 API

export function removeUser(id) {
  return axios.delete(baseUrl + `/admin/user/${id}`, { headers });
}

// 장난감
export function createToy(data) {
  return axios.post(baseUrl + `/admin/toy`, data, { headers });
}
export function fetchToyList(params) {
  return axios.get(baseUrl + `/admin/toy/list`, { params, headers });
}
export function fetchToyRequestList(params) {
  return axios.get(baseUrl + `/admin/request/toy/list`, { params, headers });
}
export function fetchToyDetails(id) {
  return axios.get(baseUrl + `/admin/toy/${id}`, { headers });
}
export function upadateToyDetails(data) {
  return axios.put(baseUrl + `/admin/toy`, data, { headers });
}
export function removeToy(id) {
  return axios.delete(baseUrl + `/admin/toy/${id}`, { headers });
}

export function fetchToyReviewList(id) {
  return axios.get(baseUrl + `/review/toy/${id}/list`, { headers });
}

// TV
export function createProgram(data) {
  return axios.post(baseUrl + `/admin/program`, data, { headers });
}
export function fetchProgramList(params) {
  return axios.get(baseUrl + `/admin/program/list`, { params, headers });
}
export function fetchProgramRequestList(params) {
  return axios.get(baseUrl + `/admin/request/program/list`, {
    params,
    headers,
  });
}
export function fetchProgramDetails(id) {
  return axios.get(baseUrl + `/admin/program/${id}`, { headers });
}
export function upadateProgramDetails(data) {
  return axios.put(baseUrl + `/admin/program`, data, { headers });
}
export function removeProgram(id) {
  return axios.delete(baseUrl + `/admin/program/${id}`, { headers });
}

// 리콜
export function createRecall(data) {
  return axios.post(baseUrl + `/admin/recall`, data, { headers });
}
export function fetchRecallList(params) {
  return axios.get(baseUrl + `/admin/recall/list`, { params, headers });
}
export function fetchRecallDetails(id) {
  return axios.get(baseUrl + `/admin/recall/${id}`, { headers });
}
export function upadateRecallDetails(data) {
  return axios.put(baseUrl + `/admin/recall`, data, { headers });
}
export function removeRecall(id) {
  return axios.delete(baseUrl + `/admin/recall/${id}`, { headers });
}

//포스트 공통
export function fetchPostList(params) {
  return axios.get(baseUrl + `/admin/post/list`, { params, headers });
}
export function fetchPostDetails(id) {
  return axios.get(baseUrl + `/admin/post/${id}`, { headers });
}
export function removePost(id) {
  return axios.delete(baseUrl + `/admin/post/${id}`, { headers });
}
export function upadatePost(data) {
  return axios.put(baseUrl + `/admin/post`, data, { headers });
}

// 똑똑정보
export function createMagazine(data) {
  return axios.post(baseUrl + `/admin/magazine`, data, { headers });
}
export function fetchMagazineList(params) {
  return axios.get(baseUrl + `/admin/magazine/list`, { params, headers });
}
export function fetchMagazineDetails(id) {
  return axios.get(baseUrl + `/admin/magazine/${id}`, { headers });
}
export function upadateMagazineDetails(data) {
  return axios.put(baseUrl + `/admin/magazine`, data, { headers });
}
export function removeMagazine(id) {
  return axios.delete(baseUrl + `/admin/magazine/${id}`, { headers });
}

// 배너관리
export function createBanner(data) {
  return axios.post(baseUrl + `/admin/banner`, data, { headers });
}
export function fetchBanner() {
  return axios.get(baseUrl + `/admin/banner`, { headers });
}
export function updateBanner(data) {
  return axios.put(baseUrl + `/admin/banner`, data, { headers });
}
export function deleteBanner(params) {
  return axios.delete(baseUrl + `/admin/banner`, { params, headers });
}

export function fetchArticleList(params) {
  return axios.get(baseUrl + `/admin/article/list`, { params, headers });
}

export function updateUserStatus(id, data) {
  return axios.put(baseUrl + `/admin/users/${id}/status`, data, { headers });
}

export function updateCertificate(data) {
  return axios.put(baseUrl + `/admin/certificate`, data, { headers });
}

export function fetchCertificate(params) {
  return axios.get(baseUrl + `/admin/certificate`, { params, headers });
}

export function upgradeStatus(data) {
  return axios.put(baseUrl + `/admin/upgrade`, data, { headers });
}

export function fetchUpgradeList(params) {
  return axios.get(baseUrl + `/admin/upgrade/list`, {
    params,
    headers,
  });
}

export function createWithdraw(data) {
  return axios.post(baseUrl + "/admin/withdraw", data, { headers });
}

// export function fetchPostList(params) {
//   return axios.get(baseUrl + `/admin/post/list`, { params, headers });
// }

//유저 - 게시글 리스트

export function fetchUserPostList(id, params) {
  return axios.get(baseUrl + `/admin/${id}/postList`, { params, headers });
}
